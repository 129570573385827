





























































































































































import { Component } from 'vue-property-decorator';
import _ from 'lodash';

import AppVue from '@/AppVue.vue';
import ExistingDraftsWarningCpt from '@/modules/reports/components/ExistingDraftsWarningCpt.vue';
import { InputFieldCpt, PlayerFinderDialogCpt } from '@/core/components';
import { PlayerCollectionModel, PlayerGridFilter, PlayerGridLookupModel } from '@/core/webapi';
import { ApiService } from '@/core/services';
import {
  GridFactory,
  GridModel,
  GridFilter,
  GridSearchCpt,
  GridPagerCpt,
  GridSelectFilterCpt,
} from '@/core/components/grid';
import { PlayerDeduplicateCpt } from '@/modules/players/components';

@Component({
  components: {
    GridSearchCpt,
    GridPagerCpt,
    GridSelectFilterCpt,
    ExistingDraftsWarningCpt,
    InputFieldCpt,
    PlayerFinderDialogCpt,
    PlayerDeduplicateCpt,
  },
})
export default class PlayersGridView extends AppVue {
  grid = {} as GridModel<PlayerCollectionModel>;
  internalCollection = [] as PlayerCollectionModel[];
  lookups = {} as PlayerGridLookupModel;
  showDeduplicateDialog = false;
  deduplicateKeepUserId = 0;

  async created() {
    await this.initializeLookups();

    this.$eventHub.$on('GRID_DATA_UPDATED', () => this.initializeInternalCollection());

    this.grid = GridFactory.create({
      filterMethod: filter => ApiService.players().filterPlayers(filter as PlayerGridFilter),
      routeName: 'players',
      defaultFilterOverrides: new GridFilter({
        group: this.$route.params.group || ((await this.anyFavourites()) ? 'favourites' : 'all'),
        organisationId: this.userContext.currentOrganisationId || null,
      }),
    });
  }

  async anyFavourites() {
    return !this.userContext.isReporter
      ? false
      : (await ApiService.players().anyFavourites(this.userContext.currentOrganisationId ?? null)).data;
  }

  async initializeInternalCollection() {
    this.internalCollection = _.cloneDeep(this.grid?.collection?.items) || [];
  }

  async initializeLookups() {
    this.lookups = (
      await ApiService.players().getPlayerGridLookupData(this.userContext.currentOrganisationId ?? null)
    ).data;
  }

  async flipFavourite(player: PlayerCollectionModel) {
    player.isFavourite
      ? await ApiService.players().removeFavourite(player.id)
      : await ApiService.players().addFavourite(player.id);

    this.$notify({
      title: this.$t('msg.success') as string,
      message: this.$t(player.isFavourite ? 'msg.removedFromFavourites' : 'msg.addedToFavourites') as string,
      type: 'success',
    });

    this.grid.update();
  }

  deduplicate(player: PlayerCollectionModel) {
    this.showDeduplicateDialog = true;
    this.deduplicateKeepUserId = player.id;
  }

  onDeduplicateClose(refreshData: boolean) {
    this.showDeduplicateDialog = false;
    this.deduplicateKeepUserId = 0;

    if (refreshData) {
      this.grid.update();
    }
  }
}
